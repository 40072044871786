<template>
  <el-cascader
    :options="deptData"
    v-model="list"
    class="ipt_width"
    popper-class="special-cascader"
    ref="refHandle"
    :props="deptProps"
    :show-all-levels="showAllLevels"
    :clearable="clearable"
    :size="size"
    :placeholder="placeholder"
    @expand-change="expandChange"
    @change="onChangeDeptValue"
  >
  </el-cascader>
</template>

<script>
export default {
  name: "DeptCascader",
  props: {
    returnObj: {
      type: Boolean,
      default: false,
    },
    showAllLevels: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "medium",
    },
    deptList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    deptIdArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
    value: {
      type: [String, Array, null],
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      list: this.deptIdArr || [],
      deptData: [],
      deptProps: {
        children: "children",
        label: "deptName",
        value: "id",
        checkStrictly: true,
        expandTrigger: "hover",
        ...this.options,
      },
    };
  },
  created() {},
  computed: {},
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        if (this.deptIdArr.length > 0) {
          this.list = this.deptIdArr;
        } else {
          if (this.list.indexOf(val) == -1) {
            this.list = [];
          }
        }
      },
    },
    deptList: {
      immediate: true,
      deep: true,
      handler: function (val) {
        if (this.disabled) {
          this.deptData = childrenDeleteFn(val);
          function childrenDeleteFn(arr) {
            arr.forEach((v) => {
              v.disabled = true;
              if (v.children && v.children.length === 0) {
                delete v.children;
              } else if (v.children && v.children.length > 0) {
                childrenDeleteFn(v.children);
              }
            });
            return arr;
          }
        } else {
          this.deptData = childrenDeleteFn(val);
          function childrenDeleteFn(arr) {
            arr.forEach((v) => {
              if (v.children && v.children.length === 0) {
                delete v.children;
              } else if (v.children && v.children.length > 0) {
                childrenDeleteFn(v.children);
              }
            });
            return arr;
          }
        }
      },
    },
  },
  mounted() {},
  methods: {
    findDeptById(deptList, id) {
      for (const dept of deptList) {
        if (dept.id === id) {
          return dept;
        }
        if (dept.children && dept.children.length > 0) {
          const result = this.findDeptById(dept.children, id);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
    // 递归查找部门路径，返回字符串格式的路径
    findDeptPath(deptList, id, path = []) {
      for (const dept of deptList) {
        if (dept.id === id) {
          return [...path, dept.deptName].join(" / ");
        }
        if (dept.children && dept.children.length > 0) {
          const result = this.findDeptPath(dept.children, id, [
            ...path,
            dept.deptName,
          ]);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
    onChangeDeptValue(e) {
      const value = e[e.length - 1];
      this.$emit("input", value);
      this.$emit("changeValue", value);
      this.$emit("changeValueArr", e);
      if (this.returnObj) {
        // 获取完整的部门路径
        const deptPath = this.findDeptPath(this.deptData, value);
        // 找到选中的部门对象
        const selectedDept = this.findDeptById(this.deptData, value);
        this.$emit("changeGetObj", { ...selectedDept, deptPath });
      }
      if (this.$refs.refHandle) {
        this.$refs.refHandle.dropDownVisible = false; //监听值发生变化就关闭它
      }
    },
    onEmpty() {
      this.list = [];
    },
    expandChange(event) {
      this.currentLevel = event.length;
      let that = this;
      setTimeout(() => {
        that.setCascaderDomEvent();
      }, 500);
    },
    // 绑定子菜单label的click监听
    setCascaderDomEvent() {
      let cascaderDom = document.querySelectorAll(
        ".special-cascader .el-cascader-menu__list"
      );
      if (cascaderDom.length >= this.currentLevel - 1) {
        let optionDom = cascaderDom[this.currentLevel];
        if (optionDom) {
          optionDom
            .querySelectorAll(".el-cascader-node__label")
            .forEach((label) => {
              label.addEventListener("click", this.cascaderCheckEvent);
            });
        }
      }
    },
    // 子菜单label监听事件方法
    cascaderCheckEvent(event) {
      let brother = event.target.previousElementSibling;
      const input = brother.querySelector('input[type="radio"]');
      if (input) {
        input.click(); // 模拟点击
      }
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss">
.el-cascader-panel .el-radio {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 10px;
  right: 10px;
}
.el-cascader-panel .el-radio__input {
  visibility: hidden !important;
}
.el-cascader-panel .el-cascader-node__postfix {
  top: 10px;
}

.ipt_width {
  width: 165px;
  margin-right: 15px;
  margin-bottom: 5px;
}
</style>
